import React, { useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import styled from 'styled-components';
import InputCustom from '../../elements/InputCustom';
import TextareaCustom from '../../elements/TextareaCustom';
import ButtonTextCustom from '../../elements/ButtonTextCustom';
import ModalImage from '../../elements/Modals/ModalImage';

import { TitleIcon, 
         HeadlineIcon,
         TrashIcon,
         ImageIcon,
         OtherAdmissionIcon, } from '../../../helpers/icons';

import { validateNoTagsHTML, } from '../../../helpers/functions';

import { ConfigurationContext } from '../../../context';

dayjs.extend(utc);
dayjs.extend(timezone);

const WrapperStyled = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    flex-direction: column;
    
    .box_form{
        display: grid; 
        grid-template-columns: repeat(2, 1fr);
        gap: 50px;
        .box_column{

        }
    }
    
    .box_fields{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        margin-bottom: 40px;
        >.title{
            color: ${props => `rgb(${props.pancolors.black})`};
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 20px;
        }
        >.field{
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-bottom: 25px;
            &.file_publication_date{
                margin-bottom: 7px;
            }
            :last-child{
                margin-bottom: 0;
            }
            .datetime_activate_at{
                width: 100%;
                .MuiOutlinedInput-notchedOutline{
                    border-color: rgba(0, 0, 0, 0.23);
                }
                &.error{
                    .MuiOutlinedInput-notchedOutline{
                        border: 2px solid ${props => `rgb(${props.pancolors.red})`}!important;
                    }
                }
            }
        }
        .field_timezone{
            margin-bottom: 10px;
        }
        .field_immediate_publication{
            .label_immediate_publication{
                margin-bottom: 0;
            }
        }
        .field_no_points{
            .label_no_points{
                margin-bottom: 0;
            }
        }
        .field_points{
            margin-bottom: 10px;
        }
        .field_description_long,
        .field_terms_and_conditions{
            margin-bottom: 0!important;
            textarea{
                height: 160px;
            }
        }
        .field_description_short{
            margin-bottom: 0!important;
            textarea{
                height: 120px;
            }
        }
        .bottom_textarea{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;

            font-size: 14px;
            margin-top: 5px;
            font-weight: 700;
            color: ${props => `rgb(${props.pancolors.dark_gray})`};
            &.error{
                color: ${props => `rgb(${props.pancolors.red})`};
            }
        }
        .box_field_description_long,
        .box_field_description_short,
        .box_field_terms_and_conditions{
            width: 100%;
            margin-bottom: 25px!important;

            .error{
                color: ${props => `rgb(${props.pancolors.red})`};
            }
        }
        .action_box_segmentation{
            margin-bottom: 25px;
        }
        .action_box_evaluation{
            margin-top: 30px;
        }
    }
    .box_cover_image,
    .box_icon{
        display: flex;
        flex-direction: row;
        margin-bottom: 25px;
        width: 100%;
        
        .image,
        .image_thumb{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 300px;
            height: 300px;
            overflow: hidden;
            img{
                float: left;
                width: 100%;
            }
            &.error{
                border: 2px solid ${props => `rgb(${props.pancolors.red})`};
            }
        }
        .image_thumb{
            background-color: ${props => `rgb(${props.pancolors.middle_gray})`};
            .icon{
                width: 120px;
                height: 120px;
                svg{
                    float: left;
                    width: 100%;
                }
            }
        }
        
        .add_image{
            display: flex;
            flex-direction: column;
            margin-left: 20px;
            align-items: flex-start;
            .bt_add_image{
                padding: 0;
                >.text{
                    margin-left: 0;
                }
                &.is_loading{
                    p{
                        color: ${props => `rgb(${props.pancolors.light_gray})`};
                    }
                    .icon svg{
                        fill: ${props => `rgb(${props.pancolors.light_gray})`};
                    }
                }
            }
            .bt_delete_image{
                padding: 0;
                margin-top: 10px;
                height: auto;
                &.is_loading{
                    p{
                        color: ${props => `rgb(${props.pancolors.light_gray})`};
                    }
                    .icon svg{
                        fill: ${props => `rgb(${props.pancolors.light_gray})`};
                    }
                }
            }
            .aclaration_bt_add_image{
                font-size: 12px;
                line-height: 14px;
                font-weight: 700;
                color: ${props => `rgb(${props.pancolors.dark_gray})`};
                margin-top: 10px;
            }
        }
    }
    .box_icon{
        display: flex;
        flex-direction: row;
        margin-bottom: 25px;
        width: 100%;
        
        .image,
        .image_thumb{
            width: 130px;
            height: 130px;
            .icon{
                width: 70px;
            }
        }
    }
    .add_aditional_information{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 30px;
        .bt_add_more_info{
            display: flex;
            flex-direction: row;
            align-self: flex-start;
            padding: 0;
            margin-bottom: 30px;
        }
        .aditional_information_el{
            margin-bottom: 10px;
        }
    }
    .actions{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        .bt_submit{

        }
        .bt_program_activation{
            margin-left: 10px;
        }
        .bt_cancel{
            margin-left: 10px;
        }
    }
`

const Form = (props) => {

    const { panColors } = useContext(ConfigurationContext);

    const { data = null,
            lang = null,
            action,
            className,
            loadingForm,
            contentByLang, 
            setContentByLang,
            labelsArr, setLabelsArr,
            labelsData,
            stock, setStock, stockData, } = props;
            
    const [ coverImage, setCoverImage ] = useState(null);
    const [ iconImage, setIconImage ] = useState(null);
    const [ openModalImage, setOpenModalImage ] = useState(false);
    const [ croppedImage, setCroppedImage ] = useState(null);
    const [ croppedImageIcon, setCroppedImageIcon ] = useState(null);
    const [ uploadRoute, setUploadRoute ] = useState(null);
    const [ uploadRouteIcon, setUploadRouteIcon ] = useState(null);

    const formStyle = {background_color: `rgba(${panColors.white}, 1)`,
                       border_color: `rgba(${panColors.middle_gray}, 1)`};

    useEffect(() => {

        if(labelsData){
            setLabelsArr(labelsData);
        }
    
        return () => {}
    }, [labelsData])

    useEffect(() => {
        if(action == "edit" && data){

            if(data.cover_image.value){
                if(!croppedImage){
                    setCoverImage(contentByLang[lang].cover_image.value);
                }
            }
        }

        return () => {
            //setFormData({});
            //toast.dismiss();
        }

    }, [data])

    useEffect(() => {
        if(stockData){
            let updateStock = {...stock};
            updateStock.value = stockData;
            setStock(updateStock);
        }
      return () => {}
    }, [stockData])

    useEffect(() => {
        return () => {
            setCoverImage(null);
        }
    }, [])
    
    const handleFormElementChange = (name, value) => {
        let updateData = {...data};
        let valid = true;
        switch (name) {
            case 'title':
                
                let valid_title = validateNoTagsHTML(value);
                if(valid_title){
                    updateData[name].error = true;
                }else{
                    updateData[name].error = false;
                }
                updateData[name].value = value;

                break;
            default:

                updateData[name].value = value;

                break;
        }

        let contentByLangUpdate = {...contentByLang};
        contentByLangUpdate[lang] = updateData;
        setContentByLang(contentByLangUpdate);

        console.log("contentByLangUpdate", contentByLangUpdate)
        
        return null;
    }

    const handleDeleteCoverImage = () => {
        setCroppedImage(null);
        setCoverImage(null);

        let contentByLangUpdate = {...contentByLang};
        contentByLangUpdate[lang].cover_image.value = "";
        setContentByLang(contentByLangUpdate);
    }

    useEffect(() => {
        
        if(croppedImage){
            
            setCoverImage(croppedImage);

            let contentByLangUpdate = {...contentByLang};
            contentByLangUpdate[lang].cover_image.cropped_image = croppedImage;
            contentByLangUpdate[lang].cover_image.upload_route = uploadRoute;
            setContentByLang(contentByLangUpdate);

        }
    
      return () => {}
    }, [croppedImage])

    useEffect(() => {
        
        if(croppedImageIcon){
            
            setIconImage(croppedImageIcon);

            let contentByLangUpdate = {...contentByLang};
            contentByLangUpdate[lang].icon.cropped_image = croppedImageIcon;
            contentByLangUpdate[lang].icon.upload_route = uploadRouteIcon;
            setContentByLang(contentByLangUpdate);

        }
    
      return () => {}
    }, [croppedImageIcon])
    

    return (
        <WrapperStyled 
            className={className}
            pancolors={panColors}
        >

            {openModalImage &&
                <ModalImage 
                    openModalImage={openModalImage} 
                    setOpenModalImage={setOpenModalImage} 
                    croppedImage={croppedImage}
                    setCroppedImage={setCroppedImage}
                    uploadRoute={uploadRoute}
                    setUploadRoute={setUploadRoute}
                    scope={"cover_image"}
                    maxSize={""}
                />
            }
            
            <div className="box_form">
                <div className="box_column">
                    <div className="box_cover_image">
                        {coverImage ?
                        <div className={`image ${data?.cover_image?.error ? " error" : ""}`}>
                            <img src={coverImage} />
                        </div>
                        :
                        <div className={`image_thumb ${data?.cover_image?.error ? " error" : ""}`}>
                            <div className="icon">
                                <ImageIcon />
                            </div>
                        </div>
                        }
                        <div className="add_image">
                            {!coverImage &&
                                <ButtonTextCustom 
                                    className={`bt_add_image${loadingForm ? " is_loading" : ""}`}
                                    text="+ Agregar / Modificar imagen de Voucher *"
                                    onClick={() => setOpenModalImage(true)}

                                    icon={null}
                                    background={{ normal: "", hover: "" }}
                                    border={{ normal: "", hover: "" }}
                                    textcolor={{ normal: panColors.primary, hover: panColors.primary }}
                                />
                            }
                            {coverImage &&
                                <ButtonTextCustom 
                                    className={`bt_delete_image${loadingForm ? " is_loading" : ""}`}
                                    text="Borrar"
                                    onClick={() => handleDeleteCoverImage()}

                                    icon={<TrashIcon />}
                                    background={{ normal: "", hover: "" }}
                                    border={{ normal: "", hover: "" }}
                                    textcolor={{ normal: panColors.red, hover: panColors.red }}
                                />
                            }
                        </div>
                    </div>

                    <div className="box_fields">
                        <div className="title">
                            Datos del beneficio
                        </div>

                        <InputCustom 
                            name={data?.title?.ref}
                            label={data?.title?.label}
                            placeholder={data?.title?.placeholder}
                            onChange={handleFormElementChange}
                            onKeyDown={null}
                            className={`field field_${data?.title?.ref}${data?.title?.error ? " error" : ""}`}
                            type="text"
                            value={data?.title?.value}
                            help={data?.title?.help}
                            isRequired={data?.title?.required}
                            styles={ formStyle }
                            label_icon={<TitleIcon />}
                            tooltip_content={data?.title?.tooltip}
                            haveError={data?.title?.error}
                        />

                        <div className="box_field_description_short">
                            <TextareaCustom 
                                name={data?.description_short?.ref}
                                label={data?.description_short?.label}
                                placeholder={data?.description_short?.placeholder}
                                onChange={handleFormElementChange}
                                onKeyDown={null}
                                className={`field field_${data?.description_short?.ref}${data?.description_short?.error ? " error" : ""}`}
                                type="text"
                                value={data?.description_short?.value}
                                help={""}
                                isRequired={data?.description_short?.required}
                                styles={ formStyle }
                                label_icon={<HeadlineIcon />}
                                tooltip_content={data?.description_short?.tooltip}
                                haveError={data?.description_short?.error}
                            />
                        </div>

                        <div className="box_field_description_long">
                            <TextareaCustom 
                                name={data?.description_long?.ref}
                                label={data?.description_long?.label}
                                placeholder={data?.description_long?.placeholder}
                                onChange={handleFormElementChange}
                                onKeyDown={null}
                                className={`field field_${data?.description_long?.ref}${data?.description_long?.error ? " error" : ""}`}
                                type="text"
                                value={data?.description_long?.value}
                                help={""}
                                isRequired={data?.description_long?.required}
                                styles={ formStyle }
                                label_icon={<HeadlineIcon />}
                                tooltip_content={data?.description_long?.tooltip}
                                haveError={data?.description_long?.error}
                            />
                        </div>
                    </div>
                </div>
                <div className="box_column">
                    <div className="box_fields">

                        <div className="box_field_terms_and_conditions">
                            <TextareaCustom 
                                name={data?.terms_and_conditions?.ref}
                                label={data?.terms_and_conditions?.label}
                                placeholder={data?.terms_and_conditions?.placeholder}
                                onChange={handleFormElementChange}
                                onKeyDown={null}
                                className={`field field_${data?.terms_and_conditions?.ref}${data?.terms_and_conditions?.error ? " error" : ""}`}
                                type="text"
                                value={data?.terms_and_conditions?.value}
                                help={""}
                                isRequired={data?.terms_and_conditions?.required}
                                styles={ formStyle }
                                label_icon={<OtherAdmissionIcon />}
                                tooltip_content={data?.terms_and_conditions?.tooltip}
                                haveError={data?.terms_and_conditions?.error}
                            />
                        </div>

                    </div>
                </div>

            </div>
            
        </WrapperStyled>
    );
}

export default React.memo(Form);