import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Amplify, Auth } from 'aws-amplify';
import { ConfigurationContext } from './ConfigurationContext';

Amplify.configure({
    Auth: {
        identityPoolId: process.env.REACT_APP_identityPoolId,//'us-east-1:452027fa-f91c-47c7-94fb-d5a2db71b1e6',
        region: process.env.REACT_APP_region,//'us-east-1',
        identityPoolRegion: process.env.REACT_APP_identityPoolRegion,//'us-east-1',
        userPoolId: process.env.REACT_APP_userPoolId,//'us-east-1_2VK4ESUGD',
        userPoolWebClientId: process.env.REACT_APP_userPoolWebClientId,//'3cdk78rvgnsvt4it6qcgikf8rb',
        authenticationFlowType: process.env.REACT_APP_authenticationFlowType,//'USER_SRP_AUTH',
    }
});

export const AuthContext = React.createContext({});

export const AuthProvider = ({ children }) => {

    const { setColors,
            getMenu } = useContext(ConfigurationContext);
    
    const [ isAuth, setIsAuth ] = useState(null);
    const [ isAuthLoading, setIsAuthLoading ] = useState(false);

    const signIn = useCallback(async (username, password) => {
        try {
            setIsAuthLoading(true);

            const user = await Auth.signIn(username, password);
            
            console.info("USER", user.signInUserSession.idToken.jwtToken);
            localStorage.setItem('access_token', user.signInUserSession.idToken.jwtToken);

            setIsAuthLoading(false);
            setIsAuth(true);
            getConfiguration();
            

            
        } catch (error) {
            setIsAuthLoading(false);
            return {success: false, message: error.message};
        }
    }, []);

    const signOut = useCallback(async () => {
        
        try {

            await Auth.signOut();
            setIsAuthLoading(false);
            setIsAuth(false);
            localStorage.removeItem('access_token');

        } catch (error) {


        }
    }, []);

    const getConfiguration = useCallback(async () => {

		setColors({
            backgroundColor: { primary: "25,132,197", secondary: "244,106,155"},
            fontColor: { primary: "10,10,10", secondary: "179,61,198"},//39,174,239
            borderColor: { primary: "237,191,51", secondary: "237,225,91"},
            buttonColor: { normal: "5,132,197", hover: "10,105,163", fontNormal: "255,255,255", fontHover: "255,255,255"},
        });

	}, []);

    useEffect(() => {
        if(localStorage.getItem('access_token')){
            setIsAuth(true);
            getMenu();
        }else{
            setIsAuth(false);
        }
    
      return () => {}

    }, [localStorage.getItem('access_token')])
    

    return (
    <AuthContext.Provider 
        value={{ 
            isAuth,
            isAuthLoading,
            signIn,
            signOut
        }}>
        {children}
    </AuthContext.Provider>
    );
};
