import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import headerImage from '../../assets/images/img_header_country.svg';
import SelectCustom from '../../components/elements/SelectCustom';
import ButtonCustom from '../../components/elements/ButtonCustom';
import { useNavigate} from 'react-router-dom';
import { CountryIcon } from '../../helpers/icons';
import LoaderComponent from '../../components/elements/LoaderComponent';
                            
import { ContentContext,
         ConfigurationContext } from '../../context';

const Wrapper = styled.div`

    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
    >.inner{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        .image{
            width: 600px;
            float: left;
        }
        .content{
            display: flex;
            width: 800px;
            flex-direction: column;
            align-items: center;
            margin-top: 15px;
            .title{
                font-size: 28px;
                line-height: 28px;
                font-weight: 700;
            }
            .description{
                text-align: center;
                font-size: 18px;
                line-height: 26px;
                color: ${props => `rgb(${props.pancolors.dark_gray})`};
                margin-top: 15px;
                .highlighted{
                    font-weight: 600;
                }
            }
            .form{
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                margin-top: 15px;
                .country{
                    width: 100%;
                    .inner{
                        width: 100%;
                    }
                }
                .actions{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-top: 10px;
                    .loader{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 44px;
                    }
                    .bt_send_country{
                        
                    }
                }
            }
        }
    }
`


const CountrySelect = (props) => {
    
    const navigate = useNavigate();
    
    const { panColors,
            setUserCountrySelected } = useContext(ConfigurationContext);
        
    const { basicData,
            setBasicData,
            editUserProfile,
            getFormsData } = useContext(ContentContext);

    const [ countries, setCountries ] = useState(null);
    const [ countryId, setCountryId ] = useState("");
    const [ countryIdError, setCountryIdError ] = useState(false);
    const [ show, setShow ] = useState(false);
    const [ loading, setLoading ] = useState(false);

    const formStyle = {background_color: `rgba(${panColors.white}, 1)`,
                       border_color: `rgba(${panColors.middle_gray}, 1)`};

    const handleFormElementChange = (name, value) => {
        
        switch (name) {
            case 'country':
                if(value === undefined){
                    setCountryId(null);
                }else{
                    setCountryId(value);
                }
                break;
            default:
                break;
        }
        
        return null;
    }

    useEffect(() => {
      
        if(basicData){
            //alert("asd")
            let countries_arr = [];
            countries_arr = basicData.user_countries.map((el, index) => {
                return {_id: el.id_country, name: el.name, prefix: el.prefix}
            })
            
            setCountries(countries_arr);
            setCountryId(basicData.country.id_country);

            if(basicData.user_countries.length > 1){
                setShow(true);
            }else{
                if(basicData.user_countries.length === 1){
                    if(basicData.country.id_country !== undefined){
                        navigate("/users");
                    }else{
                        setShow(true);
                    }
                }
            }
            
        }
    
      return () => {}
    }, [basicData])

    const handleSend = () => {

        setLoading(true);
        const country_selected = basicData.user_countries.filter(c => c.id_country === countryId);

        const response = editUserProfile({countrie: country_selected[0].id_country})
        response.then((res) => {

            setBasicData(null);
            
            const update_basicdata = {...basicData};
            update_basicdata.country = country_selected[0];
            setBasicData(update_basicdata);

            setUserCountrySelected(country_selected[0]);
            //console.log("---> setUserCountrySelected");

            const reload = getFormsData();
            reload.then(r => {
                if(r){
                    console.log("getFormsData ->", r)
                    navigate("/users");
                    setLoading(false);
                }
                
            })
            
            
        })

    }
    
    
    if(!show){
        return <div className='loader'>
                  <LoaderComponent size={40} color={panColors.dots} />
               </div>;
    }

    return (
        <Wrapper 
            pancolors={panColors}
            className="select_country"
        >
            
            <div className='inner'>
                <div className='image'>
                    <img src={headerImage} alt="" />
                </div>
                <div className='content'>
                    <div className='title'>¡Hola!</div>
                    <div className='description'>Le damos la bienvenida a la <span className='highlighted'>Home</span> de Panel. Elije el país del que quieres ver datos. 
                        <span className='highlighted'>Se abrirán todas las secciones disponibles</span> en el menú izquierdo.
                    </div>
                    <div className='form'>
                        <SelectCustom 
                            name={"country"}
                            label={"Seleccionar el país correspondiente:"}
                            placeholder={"--------"}
                            onChange={handleFormElementChange}
                            className={`field country  ${countryIdError ? " error" : ""}`}
                            type="text"
                            value={countryId}
                            help={null}
                            isRequired={false}
                            styles={ formStyle }
                            label_icon={<CountryIcon />}
                            tooltip_content={null}
                            haveError={countryIdError}
                            data={countries}
                        />

                        <div className='actions'>

                            {loading ?
                                <div className='loader'>
                                    <LoaderComponent size={40} color={panColors.dots} />
                                </div>
                                :
                                <ButtonCustom 
                                    className="bt_send_country"
                                    text="Cargar datos"
                                    onClick={handleSend}
                                    style_type="normal"
                                    disabled={countryId ? false : true}

                                    icon={null}
                                    background={{ normal: panColors.primary, hover: panColors.white }}
                                    border={{ normal: panColors.primary, hover: panColors.primary }}
                                    textcolor={{ normal: panColors.white, hover: panColors.primary }}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>

        </Wrapper>
    )
}

export default CountrySelect;
