import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import LoaderComponent from '../elements/LoaderComponent';

import { ContentContext } from '../../context';
         
import TableUsers from './../elements/DataTable/TableUsers';

const Wrapper = styled.div`
    background-color: #fff;
`

const UsersTable = (props) => {

    const { filters = null } = props;

    const { getUsers,
            basicData,
            users, 
            setUsers } = useContext(ContentContext);
    
    const [ headCells, setHeadCells ] = useState([]);
    
    useEffect(() => {
        
      if(basicData){

        const _headCells = [];
        
        if((basicData.settings.users.section_list_columns.filter(col => col.id === 'avatar')).length){
            _headCells.push({ id: 'avatar',
                             numeric: false,
                             disablePadding: false,
                             label: (basicData.settings.users.section_list_columns.filter(col => col.id === 'avatar'))[0].label,
                             align: 'center',
                             sorteable: false, });
        }
    
        if((basicData.settings.users.section_list_columns.filter(col => col.id === 'name')).length){
            _headCells.push({ id: 'name',
                             numeric: false,
                             disablePadding: false,
                             label: (basicData.settings.users.section_list_columns.filter(col => col.id === 'name'))[0].label,
                             align: 'left',
                             sorteable: true, });
        }

        if((basicData.settings.users.section_list_columns.filter(col => col.id === 'last_name')).length){
            _headCells.push({ id: 'last_name',
                             numeric: false,
                             disablePadding: false,
                             label: (basicData.settings.users.section_list_columns.filter(col => col.id === 'last_name'))[0].label,
                             align: 'left',
                             sorteable: true, });
        }
        
        if((basicData.settings.users.section_list_columns.filter(col => col.id === 'dni')).length){
            _headCells.push({ id: 'dni',
                              numeric: false,
                              disablePadding: false,
                              label: (basicData.settings.users.section_list_columns.filter(col => col.id === 'dni'))[0].label,
                              align: 'left',
                              sorteable: true,
                            });
        }

        if((basicData.settings.users.section_list_columns.filter(col => col.id === 'email')).length){
            _headCells.push({ id: 'email',
                              numeric: false,
                              disablePadding: false,
                              label: (basicData.settings.users.section_list_columns.filter(col => col.id === 'email'))[0].label,
                              align: 'left',
                              sorteable: true,
                            });
        }

        if((basicData.settings.users.section_list_columns.filter(col => col.id === 'role')).length){
            _headCells.push({ id: 'email',
                              numeric: false,
                              disablePadding: false,
                              label: (basicData.settings.users.section_list_columns.filter(col => col.id === 'role'))[0].label,
                              align: 'left',
                              sorteable: true,
                            });
        }

        if((basicData.settings.users.section_list_columns.filter(col => col.id === 'area')).length){
            _headCells.push({ id: 'area',
                              numeric: false,
                              disablePadding: false,
                              label: (basicData.settings.users.section_list_columns.filter(col => col.id === 'area'))[0].label,
                              align: 'left',
                              sorteable: true,
                            });
        }

        if((basicData.settings.users.section_list_columns.filter(col => col.id === 'pos')).length){
            _headCells.push({ id: 'email',
                              numeric: false,
                              disablePadding: false,
                              label: (basicData.settings.users.section_list_columns.filter(col => col.id === 'pos'))[0].label,
                              align: 'left',
                              sorteable: true,
                            });
        }

        if((basicData.settings.users.section_list_columns.filter(col => col.id === 'user_profile')).length){
            _headCells.push({ id: 'user_profile',
                              numeric: false,
                              disablePadding: false,
                              label: (basicData.settings.users.section_list_columns.filter(col => col.id === 'user_profile'))[0].label,
                              align: 'left',
                              sorteable: true,
                            });
        }

        if((basicData.settings.users.section_list_columns.filter(col => col.id === 'created_date')).length){
            _headCells.push({ id: 'created_date',
                              numeric: false,
                              disablePadding: false,
                              label: (basicData.settings.users.section_list_columns.filter(col => col.id === 'created_date'))[0].label,
                              align: 'left',
                              sorteable: true,
                            });
        }

        _headCells.push({ id: '',
                          numeric: false,
                          disablePadding: false,
                          label: 'Acciones',
                          align: 'right',
                          sorteable: false, });
                          
        setHeadCells(_headCells);

      }
      return () => {}
    }, [basicData])
   
    useEffect(() => {
        console.log("filters", filters)
        setUsers(null);
        getUsers(0, 25, filters);

    }, [filters])

    useEffect(() => {
        return () => {
            setUsers(null);
        }
    }, [])
    
    return (
        <Wrapper>
        
        
        {(users == null || !headCells.length || !basicData) ?
            
            <LoaderComponent />
        :
            users.users.length ?
            <TableUsers 
                headCells={headCells} 
                type={"users"}
                title={"Usuarios"} 
                data={users.users}
                getData={getUsers}
                totalRows={users.total}
                filters={filters}
                settings={basicData.settings.users}
            />
            :
            <>No hay registros para mostrar</>
        }
        
        </Wrapper>
    );
}

export default UsersTable;