import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { MdOutlineLogout } from "react-icons/md";
import { useNavigate } from 'react-router-dom';

import { ConfigurationContext,
         AuthContext,
         ContentContext } from '../../context';

import { Hamburger,
         CountryIcon } from '../../helpers/icons';

         
const Wrapper = styled.div`
    display: flex;
    position: fixed;
    width: 100%;
    padding-left: 0;
    height: 75px;
    border-bottom: 1px solid rgb(200, 200, 200);
    background-color: rgb(255, 255, 255);
    z-index: ${props => props.menuVariant === "permanent" ? "99999" : "999"};
    -webkit-box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.10);
    -moz-box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.10);
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.10);
    //background-color: skyblue;
    >.inner{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        
        .box_left{
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 27px;
            .bt_menu{
                display: flex;
                cursor: pointer;
                :hover{
                    svg{
                        fill: rgb(36, 34, 49);
                    }
                }
            }
            .logo{
                height: 60px;
                margin-left: 40px;
                img{
                    height: 100%;
                }
            }
            
        }
        .box_right{
            display: flex;
            flex-direction: row;
            align-items: center;
            .bt_change_country{
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 10px 15px;
                border-radius: 6px;
                border: 1px solid rgb(103,97,234);
                margin-right: 25px;
                background-color: #FFFFFF;
                &.active{
                    cursor: pointer;

                    :hover{
                        background-color: rgb(103,97,234);
                        p{
                            color: #FFFFFF;
                        }
                        svg{
                            fill: #FFFFFF;
                        }
                    }
                }
                
                p{
                    margin: 0 0 0 7px;
                    font-weight: 600;
                    color: rgb(103,97,234);
                }
                svg{
                    fill: rgb(103,97,234);
                }
                
            }
            .user_data{
                margin-right: 15px;
            }
            .bt_close{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                border-radius: 30px;
                border: 1px solid rgba(100, 100, 100, 1);
                width: 40px;
                height: 40px;
                margin-right: 15px;
                cursor: pointer;
                .ico{
                    display: flex;
                    font-size: 25px;
                    color: rgba(100, 100, 100);
                }
                :hover{
                    border-color: rgba(255, 0, 0, 1);
                    background-color: rgba(255, 0, 0, 1);
                    .ico{
                        color: white;
                    }
                }
            }
        }
    }
`

const Header = (props) => {
    
    const navigate = useNavigate();

    const { signOut } = useContext(AuthContext);
    
    const { basicData } = useContext(ContentContext);

    const { clientData,
            setClientData,
            userCountrySelected, 
            setUserCountrySelected } = useContext(ConfigurationContext);

    const { openDrawer, 
            setOpenDrawer,
            menuVariant } = props;
    
    const [ countryName, setCountryName ] = useState('');

    const handleCloseDrawer = () => {

        setOpenDrawer(!openDrawer);
    }

    const handleSignOut = () => {
        setClientData(null);
        signOut();
    }

    const handleChangeCountry = () => {
        setUserCountrySelected(null);
        navigate("/country");
    }

    useEffect(() => {
        console.log("userCountrySelected", userCountrySelected)
        if(userCountrySelected){
            
            setCountryName(userCountrySelected.name);
        }else{
            setCountryName('');
        }
    
      return () => {}
    }, [userCountrySelected])
    
    

    return (
        <Wrapper menuVariant={menuVariant}>
            <div className="inner">
                <div className="box_left">
                    <div className='bt_menu' onClick={() => handleCloseDrawer()}>
                        <Hamburger />
                    </div>
                    <div className="logo">
                        <img src={clientData && clientData.logo_client} />
                    </div>
                </div>
                <div className="box_right">
                
                    {countryName ?
                        <div className={`bt_change_country ${basicData?.user_countries.length > 1 && 'active'}`} onClick={() => { basicData?.user_countries.length > 1 && handleChangeCountry() }} >
                            <CountryIcon />
                            <p>{countryName}</p>
                        </div>
                    : ""}

                    <div className="user_data">
                        Bienvenid@<br/>{clientData && clientData.name_user}
                    </div>
                    <div className="bt_close" onClick={ () => handleSignOut() }>
                        <div className="ico">
                            <MdOutlineLogout />
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
  
}

export default Header;
