import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { ContentContext,
         ConfigurationContext } from '../../../context';

import { InHomeModeIcon,
         HeadlineIcon } from '../../../helpers/icons';

import { MdOutlineComment } from "react-icons/md";
import { BiTrash, BiPencil } from "react-icons/bi";
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import LoaderComponent from '../LoaderComponent';
import ButtonCustom from '../../../components/elements/ButtonCustom';
import ButtonTextCustom from '../../../components/elements/ButtonTextCustom';
import Form from '../../../components/Segmentation/Form';

import TextareaCustom from '../../../components/elements/TextareaCustom';


const DialogStyled = styled(Dialog)`
    .MuiDialog-paper{
        border-radius: 8px;
    }
    .actions{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
        .bt_submit{

        }
        .bt_cancel{
            margin-left: 10px;
        }
        .loader{
            height: 44px;
            margin-top: 0;
        }
    }
    .loader{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        margin-top: 20px;
        height: 98px;
    }
    .inner_dialog{
        display: flex;
        flex-direction: column;
        align-items: center;
        //min-width: 800px;
        width: 900px;
        box-sizing: border-box;
        padding: 35px 30px 35px 30px;
        .top{
            display: flex;
            flex-direction: row;
            width: 100%;
            .left{
                width: 300px;
                .image{
                    width: 100%;
                    img{
                        float: left;
                        width: 100%;
                    }
                }
            }
            .right{
                width: calc(100% - 320px);
                margin-left: 20px;
                .data{
                    >.items{
                        margin-bottom: 15px;
                        .item{
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            margin-bottom: 15px;
                            &:last-child{
                                margin-bottom: 0;
                            }
                            .icon{
                                display: flex;
                                font-size: 23px;
                                margin-right: 10px;
                            }
                            .value{
                                font-size: 14px;
                                /* display: flex;
                                flex-direction: row;
                                margin-right: 5px; */
                            }   
                            .highlighted{
                                font-weight: 600;
                            }
                        }
                        
                    }
                    .box_replies{
                        
                        border: 1px solid lightgray;
                        margin-bottom: 15px;
                        height: 100px;
                        overflow-y: auto;
                        overflow-x: hidden;
                        .items{
                            display: flex;
                            flex-direction: column;
                            .item{
                                position: relative;
                                padding: 15px 50px 15px 13px;
                                background-color: rgba(103, 97, 234, 0.03);
                                margin-bottom: 10px;
                                .bt_detele{
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    position: absolute;
                                    right: 15px;
                                    top: 0;
                                    background-color: red;
                                    border: 1px solid red;
                                    width: 23px;
                                    height: 23px;
                                    margin-top: 10px;
                                    font-size: 19px;
                                    line-height: 19px;
                                    font-weight: 600;
                                    color: #FFFFFF;
                                    cursor: pointer;
                                    :hover{
                                        border: 1px solid red;
                                        background-color: #FFFFFF;
                                        color: red;
                                    }
                                }
                                .user{
                                    color: rgba(103, 97, 234, 1);
                                    font-size: 12px;
                                    line-height: 12px;
                                    font-weight: 600;
                                }
                                .comment{
                                    font-size: 14px;
                                    line-height: 17px;
                                    font-weight: 400;
                                    margin-top: 5px;
                                }
                                &:last-child{
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                    .no_replies{
                        border: 1px solid lightgray;
                        padding: 10px 10px;
                        font-size: 14px;
                        line-height: 14px;
                        font-weight: 600;
                        margin-bottom: 20px;
                    }
                    .field_comment_text{
                        textarea{
                            height: 100px!important;
                        }
                    }
                }
            }
        }
        .bottom{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            margin-top: 25px;
            
            .actions{
                justify-content: center!important;
            }
        }
        .header_dialog{
            display: flex;
            flex-direction: column;
            align-items: center;
            .icon{
                width: 90px;
                svg{
                    float: left;
                    width: 100%;
                }
            }
            .title{
                margin: 20px 0 0 0;
                font-weight: 600;
                font-size: 25px;
                line-height: 35px;
                text-align: center;
                .bolder{
                    font-weight: 600;
                }
            }
            .subtitle{
                margin: 10px 0 0 0;
                font-size: 18px;
                line-height: 22px;
                color: ${props => `rgb(${props.pancolors.dark_gray})`};
            }
        }
        .content_dialog{
            display: flex;
            width: 100%;
            margin-top: 20px;
            .field{
                width: 100%;
                .datetime_activate_at{
                    width: 100%;
                }
            }
        }
        .actions_dialog{
            display: flex;
            flex-direction: column;
            width: 100%;
            >.bt_submit{
                margin-top: 20px;
            }
            >.bt_cancel{
                margin-top: 10px;
            }
        }
        .label_no_segmentation{
            margin-bottom: 0;
        }
    }
`

const CreateContainer = (props) => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const { panColors } = useContext(ConfigurationContext);
    const { sendComment, 
            getPostComment, 
            statusComments } = useContext(ContentContext);
    
    const { open, 
            setOpen,
            row,
            setRow } = props;
    
    const [ commentText, setCommentText ] = useState("");
    const [ commentTextError, setCommentTextError ] = useState(false);
    const [ loadingForm, setLoadingForm ] = useState(false);
    const [ data, setData ] = useState(null);
    
    const formStyle = {background_color: `rgba(${panColors.white}, 1)`,
                       border_color: `rgba(${panColors.middle_gray}, 1)`};

    const handleClose = () => {
        resetForm()
        setOpen(false);
    };


    const handleSubmit = () => {

        if(!commentText.length){

            setCommentTextError(true);

        }else{

            setCommentTextError(false);
            setLoadingForm(true);
            const payload = {comment_id: data._id, comment_message: commentText}
            const response = sendComment(payload);
            //console.log("payload", payload);
            //return null;
            response.then(res => {
                console.log("res", res)
                resetForm();
                handleGetData();
                setLoadingForm(false);
                //setOpen(false);
            })

        }

    }

    const resetForm = () => {

        setCommentText("");
        setCommentTextError(false);
        setData(null);
        setRow(null);

    }

    const handleDeleteComment = (reply) => {
        
        console.log("reply", reply._id, row)
        
        const payload = {
            comments_ids: JSON.stringify([{_id: reply._id}]),
            status: -1
        };
        
        const data_update = {...data};
        data_update.replies = data_update.replies.filter(r => r._id !== reply._id);
        setData(data_update);
        
        const deleted = statusComments(payload);
        deleted.then((res) => {
            //setRow(null);
            //setIsDeleted(true);
        })
        
        /*
        
        
        setIsLoading(true);
        deleted = statusComments(payload);
        deleted.then((res) => {
            setIsDeleted(true);
        })
        */
    }

    const handleGetData = () => {

        const result = getPostComment(row._id);
        console.log("result", result)
        result.then(res => {
            setData(res.comment);
        })

    }

    useEffect(() => {
        
        if(open){
            handleGetData();
        }
    
      return () => {
        
      }
    }, [open])
    
  
    console.log("data", data)

    return (
        <DialogStyled
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
            maxWidth="xl"
            pancolors={panColors}
        >
            <div className="inner_dialog">
                {!data ? 
                <div className='loader'>
                    <LoaderComponent size={35} color={panColors.dots} />
                </div>
                :
                <>
                <div className='top'>
                    <div className='left'>
                        <div className='image'>
                            <img src={`${data?.last_media}`} />
                        </div>
                    </div>
                    <div className='right'>
                        <div className='data'>
                            <div className='items'>
                                <div className='item'>
                                    <div className='icon'><HeadlineIcon /></div>
                                    <div className='value'><span className='highlighted'>Posteo:</span> { data?.posts_title }</div>
                                </div>
                                <div className='item'>
                                    <div className='icon'><MdOutlineComment /></div>
                                    <div className='value'><span className='highlighted'>Comentario: </span> { data?.comment }</div>
                                </div>
                            </div>

                            {data?.replies.length ?
                            <div className='box_replies'>
                                <div className='items'>
                                    {data && data?.replies?.map((reply, index) => {
                                        return(
                                            <div className='item'>
                                                <div className='bt_detele' onClick={() => handleDeleteComment(reply)}>
                                                    <BiTrash />
                                                </div>
                                                <div className='user'>{ reply.user_login }</div>
                                                <div className='comment'>{ reply.comment }</div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            :
                            <div className='no_replies'>No hay respuestas para mostrar</div>
                            }

                            <div className='response'>
                                    <TextareaCustom 
                                        name={"comment_text"}
                                        label={"Respuesta"}
                                        placeholder={"Agregar respuesta al comentario ..."}
                                        onChange={(name, value) => setCommentText(value)}
                                        onKeyDown={null}
                                        className={`field field_comment_text${commentTextError ? " error" : ""}`}
                                        type="text"
                                        value={commentText}
                                        help={""}
                                        isRequired={true}
                                        styles={ formStyle }
                                        label_icon={<HeadlineIcon />}
                                        tooltip_content={""}
                                        haveError={commentTextError}
                                    />
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div className='bottom'>
                    <div className="actions">
                        {!loadingForm ?
                        <>
                        <ButtonCustom 
                            className="bt_submit"
                            text={"Guardar"}
                            onClick={handleSubmit}
                            style_type="normal"
                            
                            icon={null}
                            background={{ normal: panColors.primary, hover: panColors.white }}
                            border={{ normal: panColors.primary, hover: panColors.primary }}
                            textcolor={{ normal: panColors.white, hover: panColors.primary }}
                        />
                        
                        <ButtonTextCustom 
                            className="bt_cancel"
                            text="Cancelar"
                            onClick={() => handleClose()}

                            icon={null}
                            background={{ normal: "", hover: "" }}
                            border={{ normal: "", hover: "" }}
                            textcolor={{ normal: panColors.red, hover: panColors.red }}
                        />
                        </>
                        :
                            <div className='loader'>
                                <LoaderComponent size={35} color={panColors.dots} />
                            </div>
                        }
                    </div>
                </div>
                </>
                }
                {/* <div className='header_dialog'>
                    <div className='icon'>
                        <InHomeModeIcon />
                    </div>
                    <div className='title'>Segmentación</div>
                    <div className='subtitle'>La segmentación, incluso si es “sin segmentar”, es obligatoria.</div>
                </div>
                <Form 
                    title={title}
                    setTitle={setTitle}
                    countries={countries} setCountries={setCountries}
                    areas={areas} setAreas={setAreas}
                    roles={roles} setRoles={setRoles}
                    regions={regions} setRegions={setRegions}
                    clusters={clusters} setClusters={setClusters}
                    retailers={retailers} setRetailers={setRetailers}
                    formats={formats} setFormats={setFormats}
                    idioms={idioms} setIdioms={setIdioms}
                    achievements={achievements} setAchievements={setAchievements}
                    departments={departments} setDepartments={setDepartments}
                    flags={flags} setFlags={setFlags}
                    noSegmentation={noSegmentation} setNoSegmentation={setNoSegmentation}
                    dataLoaded={true}
                />

                 */}

            </div>
        </DialogStyled>
    );
}

export default CreateContainer;