import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import LoaderComponent from '../elements/LoaderComponent';
import SelectCustom from '../elements/SelectCustom';
import { CountryIcon } from '../../helpers/icons';
import { ContentContext,
         ConfigurationContext } from '../../context';

import { IoMdRefresh } from "react-icons/io";

const WrapperStyled = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 250px;
    .loader{
        display: flex;
        flex-direction: row;
        height: 32px;
        padding: 6px 15px;
    }
    .box_moderate{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        height: 32px;
        box-sizing: border-box;
        
        
        border-radius: 6px;
        font-size: 12px;
        line-height: 12px;
        font-weight: 600;
        padding: 0 5px 0 15px;
        margin: 6px 0;
        &.entregado,
        &.cancelado{
            padding: 0 15px 0 15px;
        }
        text-transform: uppercase;
        .bt_cancel{
            cursor: pointer;
            font-size: 14px;
            line-height: 14px;
            font-weight: 600;
            margin-left: 3px;
            box-sizing: border-box;
            padding: 3px 5px;
            :hover{
                opacity: 0.5;
                //color: ${props => `rgb(${props.pancolors.black})`};
            }
        }
        .bt_refresh{
            display: flex;
            cursor: pointer;
            font-size: 18px;
            line-height: 18px;
            font-weight: 600;
            margin-left: 3px;
            box-sizing: border-box;
            padding: 3px 5px;
            color: #FFFFFF;
            :hover{
                opacity: 0.5;
                //color: ${props => `rgb(${props.pancolors.black})`};
            }
        }
    }
    .field_status{
        width: 100%;
    }
    .cancelado{
        color: #FFFFFF;
        background-color: ${props => `rgb(${props.pancolors.red})`};
    }
    .aceptado{
        color: #FFFFFF;
        background-color: ${props => `rgb(${props.pancolors.yellow})`};
    }
    .entregado{
        color: #FFFFFF;
        background-color: ${props => `rgb(${props.pancolors.green})`};
    }
`

const Redeem = (props) => {

    const { redeem_id,
            actual_status,
            handleStatusChanged } = props;

    const { statusBenefitRedeem, } = useContext(ContentContext);

    const { panColors } = useContext(ConfigurationContext);

    const [ status, setStatus ] = useState();
    const [ statusChanged, setStatusChanged ] = useState(false);
    const [ statusList, setStatusList ] = useState([]);
    const [ showTag, setShowTag ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(false);

    const formStyle = {background_color: `rgba(${panColors.white}, 1)`,
                       border_color: `rgba(${panColors.middle_gray}, 1)`};
    
    const statusListArr = [{_id: "0", name: "Pendiente"}, 
                           {_id: "1", name: "Aceptado"},
                           {_id: "2", name: "Entregado"},
                           {_id: "-1", name: "Cancelado"}]; 
    
    const getStatus = (_id) => {
        const status = statusListArr.filter((s => s._id === _id.toString()));
        if(status.length){
            return status[0].name;
        }
        return "";
    }
    const handleFormElementChange = (name, value) => {

        switch (name) {
            case "status":
                setIsLoading(true);
                const payload = { benefit_id: JSON.stringify([{_id: redeem_id}]), status: value }                
                const response = statusBenefitRedeem(payload);
                response.then((res) => {
                    setStatus(value);
                    setIsLoading(false);
                    setStatusChanged(true);
                    if(value === 1){
                        setStatusList([{_id: "2", name: "Entregado"}, {_id: "-1", name: "Cancelado"}]);
                    }
                    setShowTag(true);
                })
                
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        if(statusChanged){
            handleStatusChanged(redeem_id, status);
        }
      return () => {}
    }, [statusChanged])

    useEffect(() => {

        handleStatusList(actual_status);
        setStatus(actual_status);
    
      return () => {}
    }, [])

    
    const handleStatusList = (status) => {

        let update_status_list = [];
        switch (status) {
            case -1:
                setStatusList([]);
                break;
            case 0:
                update_status_list = [...statusList];
                update_status_list = [{_id: "1", name: "Aceptado"}, {_id: "-1", name: "Cancelado"}];
                setStatusList(update_status_list);
                break;
            case 1:
                update_status_list = [...statusList];
                update_status_list = [{_id: "2", name: "Entregado"}, {_id: "-1", name: "Cancelado"}];
                setStatusList(update_status_list);
                break;
            case 2:
                setStatusList([]);
                break;
            default:
                break;
        }
        console.log("status:::", status)
        if(status !== 0){
            setShowTag(true);
        }else{
            setShowTag(false);
        }
        //setShowTag(false);
    }
    
    const handleResetTag = () => {
        setShowTag(false);
    }

    useEffect(() => {
      console.log("statusList", statusList)
    
      return () => {}
    }, [statusList])
    
    return (
        <WrapperStyled pancolors={panColors}>
            {isLoading ? 
                <div className='loader'>
                    <LoaderComponent size={30} />
                </div>
            :

            !showTag ?
                <SelectCustom 
                    name={"status"}
                    label={""}
                    className={`field field_status`}
                    value={status}
                    isRequired={false}
                    placeholder={status === 0 ? "Pendiente" : "Seleccionar un estado"}
                    onChange={handleFormElementChange}
                    type="text"
                    help={""}
                    styles={ formStyle }
                    label_icon={<CountryIcon />}
                    tooltip_content={""}
                    haveError={false}
                    data={statusList}
                />
                :
                <div className={`box_moderate ${getStatus(status).toLowerCase()}`}>
                    <div className={``}>
                        { getStatus(status) }
                    </div>
                    {status !== -1 && status !== 2 ?
                    <div className='bt_refresh' onClick={handleResetTag}>
                        <IoMdRefresh />
                    </div>
                    :""}
                </div>
            }
        </WrapperStyled>
    );
}

export default Redeem;