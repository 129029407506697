import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import LoaderComponent from '../elements/LoaderComponent';

import { ContentContext,
         ConfigurationContext } from '../../context';
         
import TablePostsComments from './../elements/DataTable/TablePostsComments';

const WrapperStyled = styled.div`
    background-color: #fff;
`

const PostsCommentsTable = (props) => {

    const { filters = null } = props;

    const { getPostsComments,
            postsComments,
            setPostsComments, } = useContext(ContentContext);

    const { panColors } = useContext(ConfigurationContext);

    const headCells = [
        {
            id: 'date',
            numeric: false,
            disablePadding: false,
            label: 'Fecha',
            align: 'left',
            sorteable: true,
        },
        {
            id: 'user_name',
            numeric: false,
            disablePadding: false,
            label: 'Nombre y Apellido',
            align: 'left',
            sorteable: false,
        },
        {
            id: 'code',
            numeric: false,
            disablePadding: false,
            label: 'Código',
            align: 'left',
            sorteable: false,
        },
        {
            id: 'user_login',
            numeric: false,
            disablePadding: false,
            label: 'User Login',
            align: 'left',
            sorteable: true,
        },
        {
            id: 'posts_title',
            numeric: false,
            disablePadding: false,
            label: 'Título del posteo',
            align: 'left',
            sorteable: true,
        },
        {
            id: 'comment',
            numeric: false,
            disablePadding: false,
            label: 'Comentario',
            align: 'left',
            sorteable: true,
        },
        {
            id: '',
            numeric: false,
            disablePadding: false,
            label: 'Acciones',
            align: 'right',
            sorteable: false,
        }
    ];
    
    useEffect(() => {
        console.log("FILTERS", filters)
        setPostsComments(null);
        getPostsComments(0, 25, filters);

    }, [filters])

    useEffect(() => {
        return () => {
            setPostsComments(null);
        }
    }, [])
    
    return (
        <WrapperStyled pancolors={panColors}>
        {postsComments == null ?
            <LoaderComponent />
        :
        postsComments?.comments?.length ?
            <TablePostsComments
                headCells={headCells} 
                type={"comments"}
                title={"Comentarios"} 
                data={postsComments.comments} 
                getData={getPostsComments}
                totalRows={postsComments.total}
                filters={filters}
            />
            :
            <>No hay registros para mostrar</>
        }
        </WrapperStyled>
    );
}

export default PostsCommentsTable;